const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const AUTH_APP_NAME = process.env.REACT_APP_AUTH_APP_NAME;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const ADMIN_ROLE_NAME = process.env.REACT_APP_ADMIN_ROLE_NAME;
const USER_ROLE_NAME = process.env.REACT_APP_USER_ROLE_NAME;

export {
  AUTH_APP_NAME,
  AUTH_URL,
  BACKEND_URL,
  ADMIN_ROLE_NAME,
  USER_ROLE_NAME,
};
