import tw from "twin.macro";

export const LoadingContainer = tw.div`
    flex
    w-full 
    h-full 
    justify-center 
    items-center
    z-50 
    fixed 
    top-0 
    left-0 `;

export const LoadingAnimation = tw.div`
    transform
    scale-75
    `;
