import { IDataDefinitionSchema, DynamicModel } from "../../constants/types";
import { GeneralErrorResponse } from "../../domain/model/GeneralErrorResponse";
import { ALERT_STATE } from "../../components/Alert/IAlert";
import { useAlert } from "../../hooks/alert/useAlert";
import { generateIncrementingNumbers } from "./generators";

const numberGenerator = generateIncrementingNumbers();

const transformDataToTableData = (data: any[], dataDefinition: IDataDefinitionSchema) => {
    if (!data || !Array.isArray(data) || data.length === 0) return [];
    const tableData = data.map((dynamicModel: DynamicModel) => {
        const item: any = dynamicModel.values
        const transformedItem: any = Object.fromEntries(
            Object.entries(dataDefinition.fields)
                .filter(([field]) => field in item)
                .map(([field, { label }]) => [label, item[field] ?? null])
        );
        transformedItem.id = numberGenerator.next().value;
        return transformedItem;
    });

    return tableData;
};

const fetcherDataFromBackend = async (
    useCase: () => Promise<any>,
    DataDefinitionSchema: IDataDefinitionSchema,
) => {
    try {
        const data = await useCase();
        const transformedData = transformDataToTableData(data, DataDefinitionSchema);

        return transformedData;
    } catch (error) {
        if (error instanceof GeneralErrorResponse) {
            useAlert().updateAlertState({
                show: true,
                data: {
                    body: error.message,
                    color: ALERT_STATE.error,
                    title: "Person list error",
                },
            });
        }
    }
    return Promise.reject(Error);
}

export default fetcherDataFromBackend;
