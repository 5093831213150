import tw from "twin.macro";

export const GeneralInputInput = tw.input`
    p-2.5
    mt-0.5 
    border
    border-ioet-orange
    text-sm 
    text-black
    rounded-md
`;

export const GeneralCheckbox = tw.input`
h-12
w-5
checked:border-transparent
rounded-md
`;

export const selectStyle = {
  menuPortal: (base: object) => ({
    ...base,
    zIndex: 40,
    fontSize: "0.8em",
  }),
  control: (baseStyles: object) => ({
    ...baseStyles,
    fontSize: "0.8em",
    padding: "0.30em",
    borderRadius: "0.5em",
    borderColor: "rgb(252,86,48)",
  }),
  input: (baseStyles: object) => ({
    ...baseStyles,
  }),
};
