import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ProviderTree } from "@utils/index";
import { Navigation } from "./routes/routes";
import { AuthProvider } from "./contexts/AuthContext";
import { LoadingContextProvider } from "./contexts/LoadingStateContext";
import theme from "./styles/Themes";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const Providers = ProviderTree([[ThemeProvider, { theme }], [LoadingContextProvider], [AuthProvider]]);

root.render(
	<StrictMode>
		<BrowserRouter>
			<Providers>
				<CssBaseline />
				<Navigation />
			</Providers>
		</BrowserRouter>
	</StrictMode>
);
