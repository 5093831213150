import tw from "twin.macro";

export const AlertStyleDiv = tw.div`
z-50 
ml-7 
border-t-4 
rounded-b 
mr-2 
px-4 
py-3 
shadow-md 
max-w-md 
fixed 
bottom-20 
left-auto 
top-auto 
right-auto
`;
