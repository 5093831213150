import { Checkbox as MUICheckbox } from "@mui/material";
import React from "react";

export default function Checkbox() {
	const [checked, setChecked] = React.useState<boolean>(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	return (
		<MUICheckbox
			checked={checked}
			onChange={handleChange}
			onClick={(event) => event.stopPropagation()}
			inputProps={{ "aria-label": "controlled" }}
		/>
	);
}
