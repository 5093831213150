// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ALERT_STATE {
  success = "bg-teal-100 border-teal-500 text-teal-900",
  error = "bg-red-100 border-red-500 text-red-900",
  info = "bg-light-blue-100 border-light-blue-500 text-light-blue-900",
  warning = "bg-yellow-100 border-yellow-500 text-yellow-900",
}

export interface IAlert {
  title: string;
  body: string;
  color: ALERT_STATE;
}
