import { IOptions } from "../../../../pages/FormEmployee/IEmployee";

// This options are for the SelectInput Components in the FormEmployee

export const COUNTRY_OPTIONS: IOptions[] = [
  { value: "Argentina", label: "Argentina" },
  { value: "AR", label: "Argentina" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Brazil", label: "Brazil" },
  { value: "Chile", label: "Chile" },
  { value: "Colombia", label: "Colombia" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Cuba", label: "Cuba" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Honduras", label: "Honduras" },
  { value: "Mexico", label: "Mexico" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Panama", label: "Panama" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Australia", label: "Australia" },
  { value: "Canada", label: "Canada" },
  { value: "China", label: "China" },
  { value: "France", label: "France" },
  { value: "Germany", label: "Germany" },
  { value: "India", label: "India" },
  { value: "Italy", label: "Italy" },
  { value: "Japan", label: "Japan" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Russia", label: "Russia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Korea", label: "South Korea" },
  { value: "Spain", label: "Spain" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Turkey", label: "Turkey" },
  { value: "Austria", label: "Austria" },
  { value: "Belgium", label: "Belgium" },
  { value: "Denmark", label: "Denmark" },
  { value: "Egypt", label: "Egypt" },
  { value: "Greece", label: "Greece" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Ireland", label: "Ireland" },
  { value: "Kenya", label: "Kenya" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Norway", label: "Norway" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sweden", label: "Sweden" },
  { value: "Thailand", label: "Thailand" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const EMPLOYMENT_STATUS_OPTIONS: IOptions[] = [
  { value: "candidate", label: "Candidate" },
  { value: "employee", label: "Employee" },
  { value: "former_candidate", label: "Former Candidate" },
  { value: "former_employee", label: "Former Employee" },
];

export const GENDER_OPTIONS: IOptions[] = [
  { value: "MALE", label: "MALE" },
  { value: "FEMALE", label: "FEMALE" },
];

export const TSHIRT_SIZE_OPTIONS: IOptions[] = [
  { value: "XS", label: "XS" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
  { value: "XXXL", label: "XXXL" },
];

export const RELATIONSHIP_OPTIONS: IOptions[] = [
  { value: "Spouse", label: "Spouse" },
  { value: "Friend", label: "Friend" },
  { value: "Parent", label: "Parent" },
  { value: "Sibling", label: "Sibling" },
  { value: "Child", label: "Child" },
  { value: "Other", label: "Other" },
];

export const DEGREE_CHOICES: IOptions[] = [
  { value: "Associate", label: "Associate" },
  { value: "Bachelor", label: "Bachelor" },
  { value: "Master", label: "Master" },
  { value: "Doctorate", label: "Doctorate" },
  { value: "Other", label: "AssOtherociate" },
];
