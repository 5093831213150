import { useState, useEffect, useContext } from "react";
import { Employee } from "../../domain/model/Employee";
import { EmployeeProfileViewModel } from "../../pages/EmployeeProfile/EmployeeProfileViewModel";
import { EmployeeRepositoryImpl } from "../../data/repository/employeeRepositoryImpl";
import { ILoadingContextState } from "../../contexts/IContexts";
import LoadingContext from "../../contexts/LoadingStateContext";
import { GeneralErrorResponse } from "../../domain/model/GeneralErrorResponse";
import { useAlert } from "../alert/useAlert";
import { ALERT_STATE } from "../../components/Alert/IAlert";

export const useEmployee = ({ employeeId }: { employeeId?: string }) => {
	const [employee, setEmployee] = useState<Employee>();
	const { setLoading } = useContext(LoadingContext) as ILoadingContextState;
	const { alertState, updateAlertState } = useAlert();
	const employeeProfileViewModel: EmployeeProfileViewModel = new EmployeeProfileViewModel(new EmployeeRepositoryImpl());

	const getEmployee = async (getEmployeeById: string) => {
		try {
			setLoading(true);
			const getEmployeeFromUseCase = await employeeProfileViewModel.getEmployeeByIdUseCase(getEmployeeById);
			setEmployee(getEmployeeFromUseCase);
		} catch (error) {
			setLoading(false);
			if (error instanceof GeneralErrorResponse) {
				updateAlertState({
					show: true,
					data: {
						body: error.message,
						color: ALERT_STATE.error,
						title: "Get Employee Error",
					},
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (employeeId) getEmployee(employeeId);
	}, []);

	return {
		employee,
		alertState,
	};
};
