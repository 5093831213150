import { LoadingContainer, LoadingAnimation } from "./LoadingStyleComponents";
import loadingGif1 from "../../assets/logo_white_small_circles.png";
import loadingGif2 from "../../assets/logo_white_big_circles.png";
import "./Loading.css";

function Loading() {
	return (
		<LoadingContainer id='loadBackground'>
			<LoadingAnimation id='loadIcon'>
				<img src={loadingGif1} id='firstImage' alt='Loading...' />
				<img src={loadingGif2} id='secondImage' alt='Loading...' />
			</LoadingAnimation>
		</LoadingContainer>
	);
}

export default Loading;
