import { Employee } from "../domain/model/Employee";

export const DEFAULT_EMPLOYEE: Employee = {
  uuid: "",
  first_name: "",
  last_name: "",
  ioet_id: "",
  ioet_email: "",
  gender: "",
  date_of_birth: "",
  age: 0,
  ssn: null,
  cedula: "",
  ruc: "",
  address: "",
  province: "",
  zip_code: "",
  github_id: "",
  linkedin: "",
  twitter_feed: "",
  facebook: "",
  t_shirt_size: null,
  start_date: null,
  end_date: null,
  promised_review_date: null,
  country: "",
  mobile_phone: "",
  home_email: "",
  english_level: "",
  ready_for_project: "",
  contract_type: "",
  status: null,
  level: "",
  work_titles: null,
  internal_app: null,
  time_on_the_job: "",
  bank_account: [
    {
      account_number: "",
      swift_bic: "",
      beneficiary_name: "",
      weight: 0,
    },
  ],
  education: [
    {
      specialization: "",
      gpa: 0,
      start_date: undefined,
      end_date: undefined,
      institution: null,
      degree: null,
    },
  ],
  job_information: [
    {
      effective_date: "",
      location: "",
      division: "",
      department: "",
      job_title: "",
      supervisor_id: "",
      supervisor_name: "",
      supervisor_last_name: "",
    },
  ],
  employment_status: [
    {
      effective_date: "",
      status_category: "",
      comment: "",
      original_hire_date: "",
      pay_group: "",
    },
  ],
  compensation: [
    {
      effective_date: "",
      pay_rate: 0,
      change_reason: "",
      comment: "",
      transaction_currency: "",
    },
  ],
  emergency_contact: [
    {
      name: "",
      relationship: null,
      primary_contact: true,
      work_phone: "",
      work_phone_extension: "",
      mobile_phone: "",
      home_phone: "",
      email: "",
      address: "",
      city: "",
      province: "",
      zip_code: "",
      country: "",
    },
  ],
  pto: [
    {
      start_date: new Date(),
      end_date: new Date(),
      pto_type: "",
    },
  ],
  reviews: [
    {
      performed_date: "",
      status: "",
      reviewer_last_name: "",
      reviewer_name: "",
    },
  ],
};
