import { createTheme } from "@mui/material";

const theme = createTheme({
	palette: {
		secondary: {
			main: "#702F8A",
			light: "#FFEAFF",
			dark: "#612A80",
		},
	},
});

export default theme;
