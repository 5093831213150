import tw from "twin.macro";

export const BottomSheetContainer = tw.section`
    flex
    flex-row
    w-full
    z-50 
    fixed
    bottom-0
    bg-gray-100
    justify-around
    h-24
    border-y
    border-y-gray-300
    md:justify-start
    md:pl-72
    md:space-x-3
`;
