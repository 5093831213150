import { DynamicModel } from "../../constants/types";
import { PeopleRepository } from "../../domain/repository/peopleRepository";
import { ListAllPeopleInputPort } from "../../domain/useCase/ListAllPeopleInputPort";

export class ListPeopleViewModel implements ListAllPeopleInputPort {
	personRepository: PeopleRepository;

	constructor(personRepository: PeopleRepository) {
		this.personRepository = personRepository;
	}

	async listAllPeopleUseCase(): Promise<DynamicModel[]> {
		try {
			const people = await this.personRepository.listAllPeople();
			return people;
		} catch (error) {
			throw new Error();
		}
	}
}
