import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { NavBar } from "../../components/NavBar/NavBar";
import { CandidateForm } from "../../components/forms/CandidateForm/CandidateForm";
import { MainContainer, TitleContainer } from "./CandidatePageStyles";
import Loading from "../../components/Loading/Loading";
import LoadingContext from "../../contexts/LoadingStateContext";
import { ILoadingContextState } from "../../contexts/IContexts";
import { Button } from "../../components/Button/Button";
import { StyleButton } from "../../components/Button/StyleButton";

export function CandidatePage() {
	const { loading } = useContext(LoadingContext) as ILoadingContextState;
	const navigate = useNavigate();

	return (
		<>
			{loading && <Loading />}

			<NavBar />

			<MainContainer data-testid='candidate-main-form'>
				<TitleContainer>
					<StyleButton>
						<Button text='←' callback={() => navigate("/")} />
					</StyleButton>
					<p>New Candidate</p>
				</TitleContainer>
				<CandidateForm />
			</MainContainer>
		</>
	);
}
