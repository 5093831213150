/* eslint-disable @typescript-eslint/lines-between-class-members */
import { StringOrEmpty } from "../../types/types";

export class Employee {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  ioet_id?: string;
  is_new_hire?: boolean;
  ioet_email?: string;
  gender?: string;
  date_of_birth?: Date | number | StringOrEmpty;
  age?: number;
  ssn?: StringOrEmpty;
  cedula?: StringOrEmpty;
  ruc?: StringOrEmpty;
  address?: StringOrEmpty;
  province?: StringOrEmpty;
  zip_code?: StringOrEmpty;
  github_id?: StringOrEmpty;
  linkedin?: StringOrEmpty;
  twitter_feed?: StringOrEmpty;
  facebook?: StringOrEmpty;
  t_shirt_size?: StringOrEmpty;
  start_date?: Date | number | StringOrEmpty;
  end_date?: Date | number | StringOrEmpty;
  promised_review_date?: Date | number | StringOrEmpty;
  country?: StringOrEmpty;
  work_country?: StringOrEmpty;
  time_on_the_job?: string;
  mobile_phone?: StringOrEmpty;
  home_email?: StringOrEmpty;
  english_level?: StringOrEmpty;
  ready_for_project?: StringOrEmpty;
  office?: string;
  contract_type?: string;
  status?: StringOrEmpty[] | null;
  level?: StringOrEmpty;
  work_titles?: StringOrEmpty[] | null;

  city?: StringOrEmpty;
  internal_app?: StringOrEmpty;

  bank_account?: BankAccount[];
  education?: Education[];
  job_information?: JobInformation[];
  employment_status?: EmploymentStatus[];
  compensation?: Compensation[];
  emergency_contact?: EmergencyContact[];
  pto?: Pto[];
  reviews?: Reviews[];
}

export class BankAccount {
  account_number?: StringOrEmpty;
  swift_bic?: StringOrEmpty;
  beneficiary_name?: StringOrEmpty;
  weight?: number;
}

export class Education {
  specialization?: StringOrEmpty;
  gpa?: number;
  start_date?: Date | number | StringOrEmpty;
  end_date?: Date | number | StringOrEmpty;
  institution?: StringOrEmpty;
  degree?: StringOrEmpty | null | undefined;
}
export class JobInformation {
  effective_date?: Date | StringOrEmpty;
  location?: StringOrEmpty;
  division?: StringOrEmpty;
  department?: StringOrEmpty;
  job_title?: StringOrEmpty;
  supervisor_id?: StringOrEmpty;
  supervisor_name?: StringOrEmpty;
  supervisor_last_name?: StringOrEmpty;
}
export class EmploymentStatus {
  effective_date?: Date | StringOrEmpty;
  status_category?: StringOrEmpty;
  comment?: StringOrEmpty;
  original_hire_date?: Date | StringOrEmpty;
  pay_group?: StringOrEmpty;
}
export class Compensation {
  effective_date?: Date | StringOrEmpty;
  pay_rate?: number;
  change_reason?: StringOrEmpty;
  comment?: StringOrEmpty;
  transaction_currency?: StringOrEmpty;
}
export class EmergencyContact {
  name?: StringOrEmpty;
  relationship?: StringOrEmpty;
  primary_contact?: boolean;
  work_phone?: StringOrEmpty;
  work_phone_extension?: StringOrEmpty;
  mobile_phone?: StringOrEmpty;
  home_phone?: StringOrEmpty;
  email?: StringOrEmpty;
  address?: StringOrEmpty;
  city?: StringOrEmpty;
  province?: StringOrEmpty;
  zip_code?: StringOrEmpty;
  country?: StringOrEmpty;
}

export class Pto {
  start_date?: Date | StringOrEmpty;
  end_date?: Date | StringOrEmpty;
  pto_type?: StringOrEmpty;
}
export class Reviews {
  performed_date?: Date | StringOrEmpty;
  status?: StringOrEmpty;
  reviewer_name?: StringOrEmpty;
  reviewer_last_name?: StringOrEmpty;
}
