import User from "../../types/userType";
import { ADMIN_ROLE_NAME } from "../../constants/authConstant";
import {
	UserInformationContainer,
	UserInformationImage,
	UserInformationTextContainer,
	UserInformationTextElements,
} from "./UserInformationStyleComponents";

interface UserInformationProps {
	user: User | undefined;
	showEmail?: boolean;
	showAdminRole?: boolean;
	showPicture?: boolean;
}

function UserInformation({ user, showEmail, showAdminRole, showPicture }: UserInformationProps) {
	if (ADMIN_ROLE_NAME === undefined) {
		throw new Error("Admin role name should be defined");
	}

	return (
		<UserInformationContainer>
			<UserInformationTextContainer>
				<UserInformationTextElements>
					{user?.name}
					{showAdminRole ? ` (${user?.roles.includes(ADMIN_ROLE_NAME) && ADMIN_ROLE_NAME})` : ""}
				</UserInformationTextElements>
				{showEmail && <div>{user?.email}</div>}
			</UserInformationTextContainer>

			{showPicture && <UserInformationImage alt='Avatar' src={user?.picture} />}
		</UserInformationContainer>
	);
}

export default UserInformation;
