import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import User from "../../types/userType";
import Loading from "../Loading/Loading";
import { ADMIN_ROLE_NAME } from "../../constants/authConstant";
import { PEOPLE_LOGIN_URL } from "../../constants/urlConstants";

function RequiredAdmin() {
	const location = useLocation();
	const [user, setUser] = useState<User | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const { getUserInformation } = useAuth();

	useEffect(() => {
		getUserInformation().then((userInfo) => {
			setLoading(false);
			setUser(userInfo);
		});
	}, []);

	if (ADMIN_ROLE_NAME === undefined) {
		throw new Error("Admin role name should be defined");
	}

	if (loading) {
		return <Loading />;
	}

	return user?.roles.includes(ADMIN_ROLE_NAME) ? (
		<Outlet />
	) : (
		<Navigate to={PEOPLE_LOGIN_URL} state={{ from: location }} replace />
	);
}

export default RequiredAdmin;
