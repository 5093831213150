import { ICandidate } from "./ICandidate";
import { CandidateRepository } from "../../domain/repository/candidateRepository";
import { CandidateFormInputPort } from "../../domain/useCase/CandidateFormInputPort";

export class CandidateFormViewModel implements CandidateFormInputPort {
	candidateRepository: CandidateRepository;

	constructor(candidateRepository: CandidateRepository) {
		this.candidateRepository = candidateRepository;
	}

	async getCandidateIdUseCase(candidate: ICandidate): Promise<string> {
		const candidateId = await this.candidateRepository.getCandidateId(candidate);
		return candidateId;
	}

	async createCandidateUseCase(candidate: ICandidate): Promise<void> {
		await this.candidateRepository.createCandidate(candidate);
	}
}
