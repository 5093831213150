import axios from "axios";
import { BACKEND_URL } from "./authConstant";
import { GeneralErrorResponse } from "../domain/model/GeneralErrorResponse";
import { statusMessages } from "./types";

const httpClient = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {

    let message: string = "Something was wrong. Please, try again later";
    const statusCode = Number(error.response?.status)

    if (statusMessages[statusCode]) {
      message = statusMessages[statusCode] as string
    }

    const errorResponse: GeneralErrorResponse = new GeneralErrorResponse(
      message,
      error.response.status
    );

    return Promise.reject(errorResponse);
  }
);

export default httpClient;
