/* eslint-disable class-methods-use-this */
import { CandidateRepository } from "../../domain/repository/candidateRepository";
import httpClient from "../../constants/http_client";
import { ICandidate } from "../../pages/CandidatePage/ICandidate";
import {
  PEOPLE_GENERATE_IOET_ID,
  PEOPLE_CREATE_CANDIDATE,
} from "../../constants/urlConstants";

export class CandidateRepositoryImpl implements CandidateRepository {
  async getCandidateId(candidate: ICandidate): Promise<string> {
    const response = await httpClient.post(
      `${PEOPLE_GENERATE_IOET_ID}?first_name=${candidate.first_name}&last_name=${candidate.last_name}&is_candidate=true`
    );
    return response.data;
  }

  async createCandidate(candidate: ICandidate): Promise<void> {
    await httpClient.post(PEOPLE_CREATE_CANDIDATE, candidate);
  }
}
