import { Employee } from "../../domain/model/Employee";
import { EmployeeRepository } from "../../domain/repository/employeeRepository";
import { EmployeeFormInputPort } from "../../domain/useCase/EmployeeFormInputPort";

export class EmployeeFormViewModel implements EmployeeFormInputPort {
  employeeRepository: EmployeeRepository;

  constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  async createEmployeeUseCase(employee: Employee): Promise<void> {
    await this.employeeRepository.createEmployee(employee);
  }

  async editEmployeeUseCase(employee: Employee): Promise<void> {
    await this.employeeRepository.editEmployee(employee);
  }
}
