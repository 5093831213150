import tw from "twin.macro";

export const FormViewContainer = tw.section`
    flex
    flex-col
    w-full
    lg:w-7/12
    pb-20
`;

export const FormSectionContainer = tw.section`
    flex 
    flex-col 
    p-14
    pb-4 
    space-y-4 
    lg:pl-16 
    lg:pr-16
`;

export const FormAddSectionContainer = tw.button`
    flex 
    flex-row 
    flex-initial 
    space-x-3
    items-center
    text-ioet-orange
    hover:(text-ioet-red underline)
`;

export const FormTitleSectionContainer = tw.section`
    flex 
    flex-row 
    flex-initial 
    space-x-2
`;

export const FormInputsSectionContainer = tw.section`
    lg:flex 
    gap-x-12
    divide-y
`;

export const SingleInputContainer = tw.div`
    flex 
    flex-col 
    p-2  
`;

export const DoubleInputContainer = tw.div`
    flex
    flex-col
    justify-center
    gap-x-4
    w-full 
    p-2  
    md:(
        justify-start
        flex-row
        )

`;

export const InnerDoubleInputContainer = tw.div`
    flex 
    flex-col 
    w-full`;

export const ButtonsContainer = tw.div`
    md:mr-32
    md:justify-end
    md:pr-5 
    flex
    flex-row
    space-x-10 
    justify-around 
    pt-5
    `;

export const ErrorMessageInForm = tw.p`
    text-red-500
    text-sm
`;
