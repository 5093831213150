import React, { memo } from "react";
import { DataGrid, GridCell, GridColDef, GridColumnHeaders, GridRow, GridToolbar } from "@mui/x-data-grid";
import { TableContainer, TableToolBoxContainer, TableToolBoxContainerGroup } from "./TableStyleComponents";

import { generateIncrementingNumbers } from "../../data/utils/generators";

interface TableProps<T> {
	columns: GridColDef[];
	rowData: T[];
	toolBoxComponents: JSX.Element;
	style?: React.CSSProperties;
}

const memoizedRow = memo(GridRow);
const memoizedCell = memo(GridCell);
const MemoizedColumnHeaders = memo(GridColumnHeaders);

export function Table<T>({ columns, rowData, toolBoxComponents }: TableProps<T>) {
	if (rowData === undefined) return <h1>No data</h1>;

	const rowDataMemo = React.useMemo(() => rowData, [rowData]);

	return (
		<>
			<TableToolBoxContainer>
				<TableToolBoxContainerGroup>{toolBoxComponents}</TableToolBoxContainerGroup>
			</TableToolBoxContainer>
			{rowDataMemo?.length > 0 && (
				<TableContainer>
					<DataGrid
						style={{ height: "75vh", margin: "0 5vh 0 5vh" }}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10, page: 0 },
							},
						}}
						{...toolBoxComponents}
						getRowId={(row) => row?.id ?? generateIncrementingNumbers().next().value}
						rows={rowDataMemo}
						columns={columns}
						slots={{
							toolbar: GridToolbar,
							row: memoizedRow,
							cell: memoizedCell,
							columnHeaders: MemoizedColumnHeaders,
						}}
						checkboxSelection
						pageSizeOptions={[10, 25, 50, 100]}
						sx={{
							"& .MuiDataGrid-cell:hover": {
								color: "primary.main",
							},
						}}
					/>
				</TableContainer>
			)}
		</>
	);
}
