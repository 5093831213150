import { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ALERT_STATE } from "../components/Alert/IAlert";
import { useAlert } from "./alert/useAlert";
import { GeneralErrorResponse } from "../domain/model/GeneralErrorResponse";
import { triggerHydration } from "../services/RefreshDataService";
import LoadingContext from "../contexts/LoadingStateContext";
import { ILoadingContextState } from "../contexts/IContexts";
import { fetchPeople } from "../data/repository/fetchPeople";

export const useListPeople = () => {
	const navigate = useNavigate();
	const [data, setData] = useState<object[]>([]);

	const { alertState, updateAlertState } = useAlert();
	const { setLoading } = useContext(LoadingContext) as ILoadingContextState;

	const callPeople = useCallback(async () => {
		setLoading(true);
		try {
			const getPeople = await fetchPeople();
			setData(getPeople);
		} catch (error) {
			if (error instanceof GeneralErrorResponse) {
				updateAlertState({
					show: true,
					data: {
						body: error.message,
						color: ALERT_STATE.error,
						title: "Person list error",
					},
				});
			}
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		callPeople();
	}, []);

	const refreshData = async () => {
		try {
			setLoading(true);
			await triggerHydration();
			navigate("/");
		} catch (error) {
			setLoading(false);
			if (error instanceof GeneralErrorResponse) {
				updateAlertState({
					show: true,
					data: {
						body: error.message,
						color: ALERT_STATE.error,
						title: "Refresh data error",
					},
				});
			}
		}
	};

	const handleCreateEmployee = async () => navigate("employee_form");
	const handleCreateCandidate = async () => navigate("add_candidate");
	const handleRefreshData = async () => refreshData();

	return {
		alertState,
		data,
		handleCreateEmployee,
		handleRefreshData,
		handleCreateCandidate,
	};
};
