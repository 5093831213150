import { useNavigate } from "react-router-dom";
import UserInformationPopover from "../UserInformationPopover/UserInformationPopover";
import peopleLogo from "../../assets/logo_white.png";
import {
	NavBarLogoContainer,
	NavBarContainer,
	NavBarInnerContainer,
	TabButtonNavBarContainer,
	LogoAndButtonsContainer,
} from "./NavBarStyleComponents";
import { TabButton } from "../TabButton/TabButton";
import { PEOPLE_HOME_URL } from "../../constants/urlConstants";

export function NavBar() {
	const navigate = useNavigate();

	return (
		<NavBarContainer>
			<NavBarInnerContainer>
				<LogoAndButtonsContainer>
					<NavBarLogoContainer>
						<a href='/'>
							<img src={peopleLogo} alt='Logo' className='max-h-16' />
						</a>
					</NavBarLogoContainer>
					<TabButtonNavBarContainer>
						<TabButton text='Home' callback={() => navigate(PEOPLE_HOME_URL)} />
						<TabButton text='People' callback={() => navigate("/")} />
					</TabButtonNavBarContainer>
				</LogoAndButtonsContainer>
				<UserInformationPopover />
			</NavBarInnerContainer>
		</NavBarContainer>
	);
}
