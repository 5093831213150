import tw from "twin.macro";

export const UserInformationContainer = tw.div`
    flex
    items-center
    cursor-pointer
    `;

export const UserInformationTextContainer = tw.div`
    flex-col 
    hidden 
    lg:flex
    text-white
    `;

export const UserInformationTextElements = tw.div`
    flex 
    items-center 
    cursor-pointer
    font-bold
    `;

export const UserInformationImage = tw.img`
    w-16
    h-16
    rounded-full
    `;
