import { useEffect, useState } from "react";

import PopoverComponent from "../Popover/Popover";
import UserInformation from "../UserInformation/UserInformation";
import { Button } from "../Button/Button";
import { LogoutButtonContainer } from "../Button/StyleButton";
import useAuth from "../../hooks/useAuth";
import User from "../../types/userType";

import { LOGOUT_URL } from "../../constants/urlConstants";

function UserInformationPopover() {
	const [user, setUser] = useState<User | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const { getUserInformation } = useAuth();

	useEffect(() => {
		getUserInformation().then((userInfo) => {
			setUser(userInfo);
			setLoading(false);
		});
	}, []);

  return loading ? (
    <>Loading...</>
  ) : (
    <PopoverComponent mainComponent={<UserInformation user={user} showEmail showAdminRole showPicture />}>
      <LogoutButtonContainer>
        <Button text='Logout' callback={() => { window.location.href = LOGOUT_URL; }} />
      </LogoutButtonContainer>
    </PopoverComponent>
  );
}

export default UserInformationPopover;
