/* eslint-disable class-methods-use-this */
import { PeopleRepository } from "../../domain/repository/peopleRepository";
import httpClient from "../../constants/http_client";
import { DynamicModel } from "../../constants/types";
import {
  PEOPLE_BFF_PERSON
} from "../../constants/urlConstants";

export class PeopleRepositoryImpl implements PeopleRepository {
  async listAllPeople(): Promise<DynamicModel[]> {
    const response = await httpClient.get(PEOPLE_BFF_PERSON);
    const people: DynamicModel[] = (response.data).map((item:[Object]) => {
      const person = { values: item } as DynamicModel;
      return person;
    });
    return people;
  }

  createPerson(person: DynamicModel): Promise<boolean> {
    return httpClient.post(PEOPLE_BFF_PERSON, person);
  }

  updatePerson(person: DynamicModel): Promise<boolean> {
    return httpClient.put(PEOPLE_BFF_PERSON, person);
  }
}
