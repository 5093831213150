import * as yup from "yup";

export const candidateInformationSchema = yup
  .object({
    first_name: yup.string().required("This field is required"),
    last_name: yup.string().required("This field is required"),
    ioet_id: yup.string().required("This field is required"),
    employment_status: yup
      .object()
      .nullable()
      .required("This field is required"),
  })
  .required();
