/* eslint-disable jsx-a11y/label-has-associated-control */
import { UserIcon, BuildingLibraryIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { GeneralInputInput } from "../../Inputs/InputStyleComponents";
import { UseFormEmployeeActions } from "../../../hooks/useFormEmployeeActions";
import {
	FormViewContainer,
	FormSectionContainer,
	FormTitleSectionContainer,
	FormInputsSectionContainer,
	DoubleInputContainer,
	SingleInputContainer,
} from "../FormComponents";
import { PersonalInfoFormProps } from "./PersonalInfoFormProps";
import { BottomSheet } from "../../BottomSheet/BottomSheet";
import { Employee } from "../../../domain/model/Employee";
import { getDateToString } from "../../../utils/parsers";
import { SelectInput } from "../../Inputs/SelectInput/SelectInput";
import { GENDER_OPTIONS, COUNTRY_OPTIONS, TSHIRT_SIZE_OPTIONS } from "./utils/InputOptions";

export function PersonalInfoForm({ employee }: PersonalInfoFormProps) {
	const { methods, reset, register } = UseFormEmployeeActions();
	const [focusInput, setFocusInput] = useState(false);

	const auxEmployee = new Employee();
	auxEmployee.first_name = employee.first_name;
	auxEmployee.last_name = employee.last_name;
	auxEmployee.ioet_id = employee.ioet_id;
	auxEmployee.github_id = employee.github_id;
	auxEmployee.address = employee.address;
	auxEmployee.age = employee.age;
	auxEmployee.date_of_birth = getDateToString(employee.date_of_birth);
	auxEmployee.gender = employee.gender;
	auxEmployee.status = employee.status;
	auxEmployee.cedula = employee.cedula;
	auxEmployee.address = employee.address;
	auxEmployee.ruc = employee.ruc;
	auxEmployee.t_shirt_size = employee.t_shirt_size;
	auxEmployee.ssn = employee.ssn;
	auxEmployee.zip_code = employee.zip_code;
	auxEmployee.city = employee.city;
	auxEmployee.country = employee.country;
	auxEmployee.home_email = employee.home_email;
	auxEmployee.ioet_email = employee.ioet_email;
	auxEmployee.mobile_phone = employee.mobile_phone;
	auxEmployee.bank_account = employee.bank_account?.map((item) => {
		const bankAccount = {
			swift_bic: item.swift_bic,
			beneficiary_name: item.beneficiary_name,
		};
		return bankAccount;
	});

	useEffect(() => {
		reset(auxEmployee);
	}, []);

	const resetAllFields = () => {
		reset(auxEmployee);
		setFocusInput(false);
	};

	return (
		<FormProvider {...methods}>
			<FormViewContainer data-testid='employee-personal-info-form'>
				<FormSectionContainer>
					<FormTitleSectionContainer>
						<UserIcon height={30} color='#FC5630' />
						<h1 className='text-ioet-orange text-3xl font-bold'>Personal Information</h1>
					</FormTitleSectionContainer>

					<FormInputsSectionContainer>
						<div className='w-full lg:w-full'>
							<DoubleInputContainer>
								<div className='flex flex-col w-full'>
									<label htmlFor='first_name' className='text-gray-500'>
										Name
									</label>
									<GeneralInputInput
										{...register("first_name")}
										id='first_name'
										type='text'
										data-testid='personal-info-first-name-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>

								<div className='flex flex-col w-full'>
									<label htmlFor='last_name' className='text-gray-500'>
										Last Name
									</label>
									<GeneralInputInput
										{...register("last_name")}
										id='last_name'
										type='text'
										data-testid='personal-info-last-name-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>
							</DoubleInputContainer>

							<DoubleInputContainer>
								<div className='flex flex-col w-full'>
									<label htmlFor='ioet_id' className='text-gray-500'>
										Employee ID
									</label>
									<GeneralInputInput
										{...register("ioet_id")}
										id='ioet_id'
										type='text'
										data-testid='personal-info-employee-id-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>

								<div className='flex flex-col w-full'>
									<label htmlFor='date_of_birth' className='text-gray-500'>
										Birth Date
									</label>
									<GeneralInputInput
										{...register("date_of_birth")}
										id='date_of_birth'
										type='date'
										data-testid='personal-info-birth-date-date'
										onClick={() => setFocusInput(true)}
									/>
								</div>
							</DoubleInputContainer>

							<DoubleInputContainer>
								<div className='flex flex-col w-full'>
									<label htmlFor='age' className='text-gray-500'>
										Age
									</label>
									<GeneralInputInput
										{...register("age")}
										id='age'
										type='text'
										data-testid='personal-info-age-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>
							</DoubleInputContainer>

							<DoubleInputContainer>
								<div className='flex flex-col w-full'>
									<label htmlFor='cedula' className='text-gray-500'>
										ID Number
									</label>
									<GeneralInputInput
										{...register("cedula")}
										id='cedula'
										type='text'
										data-testid='personal-info-id-number-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>

								<div className='flex flex-col w-full'>
									<label htmlFor='ruc' className='text-gray-500'>
										RUC
									</label>
									<GeneralInputInput
										{...register("ruc")}
										id='ruc'
										type='text'
										data-testid='personal-info-ruc-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>
							</DoubleInputContainer>

							<SingleInputContainer>
								<label htmlFor='address' className='text-gray-500'>
									Address
								</label>
								<GeneralInputInput
									{...register("address")}
									id='address'
									type='text'
									data-testid='personal-info-address-input'
									onClick={() => setFocusInput(true)}
								/>
							</SingleInputContainer>

							<DoubleInputContainer>
								<div className='flex flex-col w-full'>
									<label htmlFor='city' className='text-gray-500'>
										City
									</label>
									<GeneralInputInput
										{...register("city")}
										id='city'
										type='text'
										data-testid='personal-info-city-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>

								<div className='flex flex-col w-full'>
									<label htmlFor='country' className='text-gray-500'>
										Country
									</label>
									<SelectInput
										disabled={false}
										id="country"
										label="Working Country"
										options={COUNTRY_OPTIONS}
										isMulti={false}
										dataTestId='personal-info-country-select'
										defaultValue={COUNTRY_OPTIONS.find((option) => option.label === employee.country)}
										onFocusSelect={() => setFocusInput(true)}
									/>
								</div>
							</DoubleInputContainer>

							<DoubleInputContainer>
								<div className='flex flex-col w-full'>
									<label htmlFor='phone' className='text-gray-500'>
										Mobile Phone
									</label>
									<GeneralInputInput
										{...register("mobile_phone")}
										id='mobile_phone'
										type='text'
										data-testid='personal-info-mobile-phone-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>

								<div className='flex flex-col w-full'>
									<label htmlFor='ioet_email' className='text-gray-500'>
										Ioet Email
									</label>
									<GeneralInputInput
										{...register("ioet_email")}
										id='ioet_email'
										type='text'
										data-testid='personal-info-ioet-email-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>
							</DoubleInputContainer>
						</div>

						<div className='w-full lg:w-1/2'>
							<SingleInputContainer>
								<label htmlFor='github_id' className='text-gray-500'>
									Github Username
								</label>
								<GeneralInputInput
									{...register("github_id")}
									id='github_id'
									type='text'
									data-testid='personal-info-github-username-input'
									onClick={() => setFocusInput(true)}
								/>
							</SingleInputContainer>

							<SingleInputContainer>
								<label htmlFor='gender' className='text-gray-500'>
									Gender
								</label>
								<SelectInput
									disabled={false}
									id="gender"
									label="Gender"
									options={GENDER_OPTIONS}
									isMulti={false}
									dataTestId='personal-info-gender-select'
									defaultValue={GENDER_OPTIONS.find((option) => option.label === employee.gender)}
									onFocusSelect={() => setFocusInput(true)}
								/>
							</SingleInputContainer>

							<SingleInputContainer>
								<label htmlFor='ssn' className='text-gray-500'>
									SSN
								</label>
								<GeneralInputInput
									{...register("ssn")}
									id='ssn'
									type='text'
									data-testid='personal-info-ssn-input'
									onClick={() => setFocusInput(true)}
								/>
							</SingleInputContainer>

							<SingleInputContainer>
								<label htmlFor='t_shirt_size' className='text-gray-500'>
									T-Shirt Size
								</label>
								<SelectInput
									disabled={false}
									id="t_shirt_size"
									label="T-shirt Size"
									options={TSHIRT_SIZE_OPTIONS}
									isMulti={false}
									dataTestId='personal-info-tshirt-size-input'
									defaultValue={TSHIRT_SIZE_OPTIONS.find((option) => option.label === employee.t_shirt_size)}
									onFocusSelect={() => setFocusInput(true)}
								/>
							</SingleInputContainer>

							<SingleInputContainer>
								<label htmlFor='zip_code' className='text-gray-500'>
									Zip Code
								</label>
								<GeneralInputInput
									{...register("zip_code")}
									id='zip_code'
									type='text'
									data-testid='personal-info-zip-code-input'
									onClick={() => setFocusInput(true)}
								/>
							</SingleInputContainer>

							<SingleInputContainer>
								<label htmlFor='home_email' className='text-gray-500'>
									Personal Email
								</label>
								<GeneralInputInput
									{...register("home_email")}
									id='home_email'
									type='text'
									data-testid='personal-info-personal-email-input'
									onClick={() => setFocusInput(true)}
								/>
							</SingleInputContainer>
						</div>
					</FormInputsSectionContainer>
				</FormSectionContainer>

				<FormSectionContainer>
					<FormTitleSectionContainer>
						<BuildingLibraryIcon height={30} color='#FC5630' />
						<h1 className='text-ioet-orange text-3xl font-bold'>Bank Account Information</h1>
					</FormTitleSectionContainer>

					<FormInputsSectionContainer>
						<div className='w-full lg:w-full'>
							<DoubleInputContainer>
								<div className='flex flex-col w-full'>
									<label htmlFor='swift_bic_aba' className='text-gray-500'>
										SWIFT/BIC or ABA
									</label>
									<GeneralInputInput
										{...register("bank_account.0.swift_bic")}
										id='swift_bic'
										type='text'
										data-testid='personal-info-swift-input'
										onClick={() => setFocusInput(true)}
									/>
								</div>
							</DoubleInputContainer>
						</div>

						<div className='w-full lg:w-1/2'>
							<SingleInputContainer>
								<label htmlFor='beneficiary_name' className='text-gray-500'>
									Beneficiary Name
								</label>
								<GeneralInputInput
									{...register("bank_account.0.beneficiary_name")}
									id='beneficiary_name'
									type='text'
									data-testid='personal-info-beneficiary-name-input'
									onClick={() => setFocusInput(true)}
								/>
							</SingleInputContainer>
						</div>
					</FormInputsSectionContainer>
				</FormSectionContainer>
			</FormViewContainer>
			{focusInput && <BottomSheet handleOnCancel={resetAllFields} handleOnSave={() => console.log("onSave")} />}
		</FormProvider>
	);
}
