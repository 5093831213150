import tw from "twin.macro";

export const TableContainer = tw.div`
    w-full
    mx-auto
    lg:max-h-45vh
    xl:max-h-65vh
    mt-14
    `;

export const TableToolBoxContainer = tw.div`
    flex
    justify-around
    items-center
    mb-4
    px-4
    rounded-t-md
    bg-gray-50
    flex-wrap
    `;

export const TableToolBoxContainerGroup = tw.div`
    sm:(flex
    space-x-3
    flex-wrap)
    `;
