/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormProvider } from "react-hook-form";
import { useEffect } from "react";
import { useFormCandidateActions } from "../../../hooks/useFormCandidateActions";
import { GeneralInputInput } from "../../Inputs/InputStyleComponents";
import { SelectInput } from "../../Inputs/SelectInput/SelectInput";
import { StyleCandidateButton } from "../../Button/StyleButton";
import { Button } from "../../Button/Button";
import { EMPLOYMENT_STATUS_OPTIONS } from "../PersonalInfoForm/utils/InputOptions";
import { Alert } from "../../Alert/Alert";
import { InnerDoubleInputContainer, DoubleInputContainer, ButtonsContainer, ErrorMessageInForm } from "../FormComponents";

export function CandidateForm() {
	const {
		methods,
		register,
		getCandidateId,
		watch,
		setValue,
		handleSubmit,
		onSubmit,
		alertState,
		resetAllFields,
		resetSelectInputFlag,
		errors,
	} = useFormCandidateActions();

	const watchFields = watch(["first_name", "last_name"]);
	const FIRST_NAME = watchFields[0];
	const LAST_NAME = watchFields[1];

	useEffect(() => {
		if (!FIRST_NAME || !LAST_NAME) return;
		const getData = setTimeout(async () => {
			try {
				const candidateIoetId = await getCandidateId({
					first_name: FIRST_NAME,
					last_name: LAST_NAME,
				});
				setValue("ioet_id", candidateIoetId);
			} catch (error) {
				return null;
			}
			return () => clearTimeout(getData);
		}, 1000);
	}, watchFields);

	return (
		<FormProvider {...methods}>
			{alertState.show && <Alert {...alertState.data} />}
			<div className='md:max-w-lg md:ml-32 m-8' data-testid='candidate-main-form'>
				<DoubleInputContainer className='md:flex flex-row'>
					<InnerDoubleInputContainer>
						<label htmlFor='first_name' className='text-gray-500'>
							Name
						</label>
						<GeneralInputInput
							data-testid='first-name-input'
							{...register("first_name")}
							type='text'
							id='first_name'
						/>
						{errors.first_name && <ErrorMessageInForm>{errors.first_name.message}</ErrorMessageInForm>}
					</InnerDoubleInputContainer>

					<InnerDoubleInputContainer>
						<label htmlFor='last_name' className='text-gray-500'>
							Last Name
						</label>
						<GeneralInputInput data-testid='last-name-input' {...register("last_name")} type='text' id='last_name' />
						{errors.last_name && <ErrorMessageInForm>{errors.last_name.message}</ErrorMessageInForm>}
					</InnerDoubleInputContainer>
				</DoubleInputContainer>

				<DoubleInputContainer className='md:flex flex-row'>
					<InnerDoubleInputContainer>
						<label htmlFor='ioet_id' className='text-gray-500'>
							Candidate ID
						</label>
						<GeneralInputInput {...register("ioet_id")} data-testid='ioet-id-input' type='text' id='ioet_id' />
						{errors.ioet_id && <ErrorMessageInForm>{errors.ioet_id.message}</ErrorMessageInForm>}
					</InnerDoubleInputContainer>

					<InnerDoubleInputContainer>
						<label htmlFor='employment_status' className='text-gray-500'>
							Employment Status
						</label>
						<SelectInput
							key={resetSelectInputFlag.toString()}
							dataTestId='employment-status-select'
							disabled={false}
							id='employment_status'
							options={EMPLOYMENT_STATUS_OPTIONS}
							isMulti={false}
							label='Employment Status'
							onFocusSelect={() => {
								console.log(".");
							}}
						/>
						{errors.employment_status && <ErrorMessageInForm>This field is required</ErrorMessageInForm>}
					</InnerDoubleInputContainer>
				</DoubleInputContainer>
			</div>
			<ButtonsContainer data-testid='candidate-buttons'>
				<StyleCandidateButton color="#16BAC5">
					<Button callback={handleSubmit(onSubmit)} type='submit' text='Save' data-testid='candidate-save-button' />
				</StyleCandidateButton>
				<StyleCandidateButton color="#861388">
					<Button callback={() => resetAllFields()} text='Cancel' data-testid='candidate-close-button' />
				</StyleCandidateButton>
			</ButtonsContainer>
		</FormProvider>
	);
}
