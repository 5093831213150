import tw from "twin.macro";

export const LateralBarContainer = tw.section`
    bg-ioet-light-orange
    p-10
    md:min-w-[300px]
`;

export const InformationBlock = tw.nav`
    flex 
    space-x-2
    pt-2
    items-center
`;

export const TitleAndContentBlock = tw.div`
    py-4
`;

export const LateralBarTitles = tw.h2`
    font-bold
    text-lg
`;

export const ManagerImage = tw.img`
    h-8
    w-8
    bg-white
    rounded-full
`;
