import tw from "twin.macro";

export const HeaderContainer = tw.header`
    lg:flex
    lg:flex-row
    flex-col
    items-center
    pt-24
    bg-gradient-orange-to-red
`;

export const ProfileImage = tw.img`
    lg:(
        ml-16 
        mb-10
    )
    h-48 
    w-48 
    ml-10
    m-0
    rounded-full 
    bg-white
    min-w-min
`;

export const ProfileMainInfoContainer = tw.div`
    lg:h-60
    h-40
    flex
    flex-col
    justify-between
`;

export const ProfileMainInfo = tw.div`
    pl-10
    pt-8
`;

export const TabsContainer = tw.div`
    flex 
    h-1/4
`;

export const NavItem = tw.li`
    inline-block
    text-white
`;
