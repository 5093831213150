import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Detail from "../components/Typography/Detail";
import { IDependencyProp } from "../hooks/useColumns";
import { Calendar } from "../components/Calendar";
import { parseDate } from "./parsers";
import BasicSelect from "../components/Select/Select";
import { PEOPLE_EMPLOYEE_PROFILE_URL } from "../constants/urlConstants";
import { Checkbox } from "../components/Checkbox";
import { Sidebar } from "../components/Sidebar";

export const detailInterface = ({ value, frozen }: IDependencyProp) => {
	return <Detail bold={frozen}>{value}</Detail>;
};

export const checkboxInterface = () => <Checkbox />;

export const dateInterface = ({ value }: IDependencyProp) => <Calendar dateValue={parseDate(value)} />;

export const arrayInterface = ({ value }: IDependencyProp) => <BasicSelect values={value} />;

export const objectArrayInterface = (props: IDependencyProp) => (
	<Sidebar anchorPosition={["right"]} propsColumnsDependency={props} />
);

export const actionInterface = ({ value }: IDependencyProp) => {
	const navigate = useNavigate();
	return (
		<Button
			variant='text'
			color='secondary'
			onClick={() => {
				navigate(`${PEOPLE_EMPLOYEE_PROFILE_URL}${value}`, {
					state: value,
				});
			}}
			tabIndex={0}
		/>
	);
};
