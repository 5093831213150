import { Employee } from "../../domain/model/Employee";

type ValidEmployeeDates = Pick<
  Employee,
  "start_date" | "end_date" | "promised_review_date" | "date_of_birth"
>;

export function parseEmployeeDates(
  employees: Array<Employee>,
  fields: Array<keyof ValidEmployeeDates>
): Array<Employee> {
  employees.forEach((employee) => {
    fields.forEach((field) => {
      const dateString = employee[field];
      if (dateString && typeof dateString === "string") {
        const [day, month, year] = dateString.split("-");
        employee[field] = new Date(
          Number(year),
          Number(month)-1,
          Number(day)
        );
      }
    });
    return employee;
  });
  return employees;
}
