import { Route, Routes } from "react-router-dom";
import LoginStatusContainer from "../components/LoginStatusContainer/LoginStatusContainer";
import { HomeScreen } from "../pages/HomeScreen/HomeScreen";
import RequiredAdmin from "../components/Auth/requireAdmin";
import {
	PEOPLE_BASE_URL,
	PEOPLE_LOGIN_URL,
	PEOPLE_HOME_URL,
	PEOPLE_EMPLOYEE_PROFILE_ID_URL,
	PEOPLE_ADD_CANDIDATE_URL,
} from "../constants/urlConstants";
import { CandidatePage } from "../pages/CandidatePage/CandidatePage";
import { EmployeeProfile } from "../pages/EmployeeProfile/EmployeeProfile";
import { ListPeople } from "../pages/ListPeople/ListPeople";

export function Navigation() {
	return (
		<Routes>
			<Route path={PEOPLE_LOGIN_URL} element={<LoginStatusContainer />} />
			<Route path={PEOPLE_BASE_URL} element={<RequiredAdmin />}>
				<Route path={PEOPLE_ADD_CANDIDATE_URL} element={<CandidatePage />} />
				<Route index path={PEOPLE_BASE_URL} element={<ListPeople />} />
				<Route path={PEOPLE_HOME_URL} element={<HomeScreen />} />
				<Route path={PEOPLE_EMPLOYEE_PROFILE_ID_URL} element={<EmployeeProfile />} />
			</Route>
			<Route path='*' element={<h1>Page Not Found </h1>} />
		</Routes>
	);
}
