import { NavBar } from "../../components/NavBar/NavBar";

export function HomeScreen() {
	return (
		<>
			<NavBar />
			<div className='flex flex-col items-center justify-center h-screen'>
				<h1 className='text-6xl font-bold'>Welcome to People App</h1>
			</div>
		</>
	);
}
