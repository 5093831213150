import { useState, useEffect } from "react";
import { Popover } from "react-tiny-popover";
import { StylePopoverContainer } from "./PopoverContainerStyleComponents";
import { PopoverComponentProps } from "./IPopover";

function PopoverComponent({ isOpen, onClick, children, mainComponent }: PopoverComponentProps) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	useEffect(() => {
		if (isOpen === false) {
			setIsPopoverOpen(isOpen);
		}
	}, [isOpen]);

	return (
		<Popover
			isOpen={isPopoverOpen}
			positions={["bottom"]}
			align='end'
			onClickOutside={() => {
				setIsPopoverOpen(false);
			}}
			// eslint-disable-next-line react/no-unstable-nested-components
			content={({ childRect }) => <StylePopoverContainer width={childRect.width}>{children}</StylePopoverContainer>}
		>
			<div
				onClick={(e) => {
					e.stopPropagation();
					setIsPopoverOpen(!isPopoverOpen);
					if (onClick) {
						onClick(!isPopoverOpen);
					}
				}}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            setIsPopoverOpen(!isPopoverOpen);
            if (onClick) {
              onClick(!isPopoverOpen);
            }
          }
        }}
        role="button"
        tabIndex={0}
			>
				{mainComponent}
			</div>
		</Popover>
	);
}

export default PopoverComponent;
