/* eslint-disable class-methods-use-this */
import { EmployeeRepository } from "../../domain/repository/employeeRepository";
import httpClient from "../../constants/http_client";
import { Employee } from "../../domain/model/Employee";
import {
  PEOPLE_API_ADD_EMPLOYEE,
  PEOPLE_API_EDIT_EMPLOYEE,
  PEOPLE_API_LIST_EMPLOYEE,
  PEOPLE_API_GET_EMPLOYEE,
} from "../../constants/urlConstants";
import { parseEmployeeDates } from "../utils/parseEmployeeDates";

export class EmployeeRepositoryImpl implements EmployeeRepository {
  async getAllEmployees(): Promise<Employee[]> {
    const defaultParams = { sort: "asc", field: "lastName" };
    const response = await httpClient.get(PEOPLE_API_LIST_EMPLOYEE, {
      params: defaultParams,
    });
    const employees: Employee[] = parseEmployeeDates(response.data, [
      "start_date",
      "end_date",
      "promised_review_date",
    ]);
    return employees;
  }

  createEmployee(employee: Employee): Promise<boolean> {
    return httpClient.post(PEOPLE_API_ADD_EMPLOYEE, employee);
  }

  editEmployee(employee: Employee): Promise<boolean> {
    return httpClient.post(PEOPLE_API_EDIT_EMPLOYEE, employee);
  }

  async getEmployeeById(employeeId: string): Promise<Employee> {
    const response = await httpClient.get(PEOPLE_API_GET_EMPLOYEE, {
      params: { id: employeeId },
    });
    return response.data;
  }
}
