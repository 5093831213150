import { useContext } from "react";
import LoadingContext from "../../contexts/LoadingStateContext";
import { NavBar } from "../../components/NavBar/NavBar";
import { Alert } from "../../components/Alert/Alert";
import { Button } from "../../components/Button/Button";
import { StyleButton } from "../../components/Button/StyleButton";
import { Table } from "../../components/Table/Table";
import useColumns from "../../hooks/useColumns";
import { ILoadingContextState } from "../../contexts/IContexts";
import Loading from "../../components/Loading/Loading";
import {
	actionInterface,
	arrayInterface,
	checkboxInterface,
	dateInterface,
	detailInterface,
	objectArrayInterface,
} from "../../utils/displayColumnInterfaces";
import { useListPeople } from "../../hooks/useListPeople";

export function ListPeople() {
	const { alertState, data: personRowData, handleRefreshData, handleCreateCandidate } = useListPeople();
	const { columns } = useColumns({
		simple_string: detailInterface,
		email: detailInterface,
		date: dateInterface,
		checkbox: checkboxInterface,
		details_button: actionInterface,
		ACTION: actionInterface,
		array: arrayInterface,
		object_modal: objectArrayInterface,
	});

	const { loading } = useContext(LoadingContext) as ILoadingContextState;

	return (
		<>
			{loading && <Loading />}
			{alertState.show && <Alert {...alertState.data} />}
			<NavBar />
			<Table
				columns={columns}
				rowData={personRowData}
				toolBoxComponents={
					<>
						<StyleButton>
							<Button text='Add Candidate' callback={handleCreateCandidate} />
						</StyleButton>
						<StyleButton>
							<Button text='Refresh' callback={handleRefreshData} />
						</StyleButton>
					</>
				}
			/>
		</>
	);
}
