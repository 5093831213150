import tw from "twin.macro";

export const LoginPageContainer = tw.div`
    flex 
    justify-center 
    items-center
    w-screen
    h-screen
`;

export const LoginCard = tw.div`
    flex
    flex-col
    items-center
    rounded-2xl
    bg-white
    xl:m-80
    md:m-40
    m-10
    pb-4
    shadow-inner
    bg-opacity-90`;

export const LogoImage = tw.img`
    transform 
    scale-90
    border-b-2
    border-ioet-purple
`;
