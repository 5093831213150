import React from "react";
import { ALERT_STATE } from "../../components/Alert/IAlert";
import { IuseAlert } from "./IUseAlert";

export const INIT_ALERT_DATA = {
  data: {
    body: "",
    title: "",
    color: ALERT_STATE.info,
  },
  show: false,
  showTimeSec: 5,
};

export const useAlert = () => {
  const [alertState, setAlertState] =
    React.useState<IuseAlert>(INIT_ALERT_DATA);

  const updateAlertState = (state: IuseAlert) => setAlertState(state);

  React.useEffect(() => {
    if (alertState.show) {
      setTimeout(
        () => updateAlertState(INIT_ALERT_DATA),
        alertState.showTimeSec || INIT_ALERT_DATA.showTimeSec * 1000
      );
    }
  }, [alertState]);

  return { alertState, updateAlertState };
};
