import { useMemo } from "react";
import { dataDefinitionSchema } from "src/constants/types";
import { GridColDef } from "@mui/x-data-grid";
import { transformColumnLabel } from "../utils/transformColumnLabel";

export interface IDependencyProp {
	value: any;
	frozen: boolean;
	label: string;
}

export type IDependencyComponent = (props: IDependencyProp) => JSX.Element;

const MIN_WIDTH = 200;

const useColumns = (componentMap: Record<string, IDependencyComponent>) => {
	const columns = useMemo(() => {
		const schemaFields = dataDefinitionSchema.fields;
		return Object.keys(schemaFields).map((columnFields) => {
			const { label, view_tag: viewTag, frozen } = schemaFields[columnFields];

			const toUppercaseColumnLabel = transformColumnLabel(label);

			const SelectedComponent = componentMap[viewTag];

			const column: GridColDef = {
				field: columnFields,
				headerName: toUppercaseColumnLabel,
				minWidth: MIN_WIDTH,
				headerAlign: "left",
				align: "left",

				renderCell: (params) => {
					return (
						<div style={{ whiteSpace: "normal", wordBreak: "break-all", width: "100%" }}>
							<SelectedComponent {...({ value: params?.value, frozen, label } as IDependencyProp)} />
						</div>
					);
				},
			};

			return column;
		});
	}, [dataDefinitionSchema.fields]);

	return { columns };
};

export default useColumns;
