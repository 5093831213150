import * as yup from "yup";

export const employeeInformationSchema = yup
  .object({
    ioet_id: yup.string().required("This field is required"),
    first_name: yup.string().required("This field is required"),
    last_name: yup.string().required("This field is required"),
    ioet_email: yup
      .string()
      .email("Invalid email")
      .required("This field is required"),
  })
  .required("Some fields are missing");
