import tw from "twin.macro";

export const NavBarContainer = tw.nav`
    bg-ioet-purple
    sticky 
    bg-gradient-orange-to-red
    w-full 
    h-20
    top-0 
    z-1
    pr-3.5
`;

export const NavBarInnerContainer = tw.div`
    mx-2
    flex
    h-20
    items-center
    justify-between
`;

export const NavBarLogoContainer = tw.div`
    flex
    min-w-fit
    items-center
`;

export const TabButtonNavBarContainer = tw.div`
    flex
    h-full
    w-11/12
`;

export const UserInformationPopoverContainer = tw.div`
    flex
    justify-end
`;

export const LogoAndButtonsContainer = tw.div`
    flex
    h-full
    w-9/12
`;
