import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DEFAULT_EMPLOYEE } from "../constants/defaultEmployee";
import { GeneralErrorResponse } from "../domain/model/GeneralErrorResponse";
import { EmployeeRepositoryImpl } from "../data/repository/employeeRepositoryImpl";
import { useAlert } from "./alert/useAlert";
import { EmployeeFormViewModel } from "../pages/FormEmployee/employeeFormViewModel";
import resolverManager from "../constants/resolverManager";
import { ALERT_STATE } from "../components/Alert/IAlert";
import {
	IEmployee,
	IEducation,
	IBankAccount,
	IJobInformation,
	IEmploymentStatus,
	ICompensation,
	IEmergencyContact,
	IPto,
	IReviews,
} from "../pages/FormEmployee/IEmployee";
import { EmployeeFormInputPort } from "../domain/useCase/EmployeeFormInputPort";
import {
	Employee,
	Education,
	BankAccount,
	JobInformation,
	EmploymentStatus,
	Compensation,
	EmergencyContact,
	Pto,
	Reviews,
} from "../domain/model/Employee";
import LoadingContext from "../contexts/LoadingStateContext";
import { ILoadingContextState } from "../contexts/IContexts";

export const UseFormEmployeeActions = (edition = false) => {
	const employeeUseCases: EmployeeFormInputPort = new EmployeeFormViewModel(new EmployeeRepositoryImpl());
	const { setLoading } = useContext(LoadingContext) as ILoadingContextState;
	const { alertState, updateAlertState } = useAlert();
	const navigate = useNavigate();

	const methods = useForm<Employee>({
		resolver: resolverManager.employeeInformationResolver,
		defaultValues: DEFAULT_EMPLOYEE,
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { isDirty, dirtyFields, defaultValues },
	} = methods;

	const resetAllFields = () => {
		reset(defaultValues);
	};

	const onSubmit = async (employeeForm: IEmployee) => {
		setLoading(true);
		try {
			const employee: Employee = new Employee();
			employee.first_name = employeeForm.first_name;
			employee.last_name = employeeForm.last_name;
			employee.ioet_id = employeeForm.ioet_id;
			employee.ioet_email = employeeForm.ioet_email;
			employee.uuid = employeeForm.uuid;
			employee.is_new_hire = employeeForm.is_new_hire;
			employee.gender = employeeForm.gender?.value;
			employee.date_of_birth = employeeForm.date_of_birth;
			employee.age = employeeForm.age;
			employee.ssn = employeeForm.ssn;
			employee.cedula = employeeForm.cedula;
			employee.ruc = employeeForm.ruc;
			employee.address = employeeForm.address;
			employee.province = employeeForm.province;
			employee.zip_code = employeeForm.zip_code;
			employee.city = employeeForm.city;
			employee.github_id = employeeForm.github_id;
			employee.linkedin = employeeForm.linkedin;
			employee.twitter_feed = employeeForm.twitter_feed;
			employee.facebook = employeeForm.facebook;
			employee.t_shirt_size = employeeForm.t_shirt_size?.value;
			employee.start_date = employeeForm.start_date;
			employee.end_date = employeeForm.end_date;
			employee.promised_review_date = employeeForm.promised_review_date;
			employee.country = employeeForm.country;
			employee.mobile_phone = employeeForm.mobile_phone;
			employee.home_email = employeeForm.home_email;
			employee.english_level = employeeForm.english_level;
			employee.ready_for_project = employeeForm.ready_for_project;
			employee.contract_type = employeeForm.contract_type?.value;
			employee.status = employeeForm.status;
			employee.level = employeeForm.level;
			employee.work_titles = employeeForm.work_titles;
			employee.internal_app = employeeForm.internal_app;

			const education: Education[] | undefined = employeeForm.education
				?.map((item: IEducation) => {
					const educationItem: Education = new Education();
					educationItem.degree = item.degree?.value;
					educationItem.end_date = item.end_date ? new Date(item.end_date) : undefined;
					educationItem.gpa = item.gpa;
					educationItem.institution = item.institution?.value;
					educationItem.specialization = item.specialization;
					educationItem.start_date = item.start_date ? new Date(item.start_date) : undefined;
					return educationItem;
				})
				.filter((item) => item);
			employee.education = education;

			const bankAccount: BankAccount[] | undefined = employeeForm.bank_account
				?.map((account_form: IBankAccount) => {
					const bankAccountItem: BankAccount = new BankAccount();
					bankAccountItem.account_number = account_form.account_number;
					bankAccountItem.beneficiary_name = account_form.beneficiary_name;
					bankAccountItem.swift_bic = account_form.swift_bic;
					bankAccountItem.weight = account_form.weight;
					return bankAccountItem;
				})
				.filter((item) => item);
			employee.bank_account = bankAccount;

			const jobInformation: JobInformation[] | undefined = employeeForm.job_information
				?.map((job_info_form: IJobInformation) => {
					const jobInformationItem: JobInformation = new JobInformation();
					jobInformationItem.effective_date = job_info_form.effective_date;
					jobInformationItem.location = job_info_form.location;
					jobInformationItem.division = job_info_form.division;
					jobInformationItem.department = job_info_form.department;
					jobInformationItem.job_title = job_info_form.job_title;
					jobInformationItem.supervisor_id = job_info_form.supervisor_id;
					jobInformationItem.supervisor_name = job_info_form.supervisor_name;
					jobInformationItem.supervisor_last_name = job_info_form.supervisor_last_name;
					return jobInformationItem;
				})
				.filter((item) => item);
			employee.job_information = jobInformation;

			const employmentStatus: EmploymentStatus[] | undefined = employeeForm.employment_status
				?.map((employment_status_form: IEmploymentStatus) => {
					const employmentStatusItem: EmploymentStatus = new EmploymentStatus();
					employmentStatusItem.effective_date = employment_status_form.effective_date;
					employmentStatusItem.status_category = employment_status_form.status_category;
					employmentStatusItem.comment = employment_status_form.comment;
					employmentStatusItem.original_hire_date = employment_status_form.original_hire_date;
					employmentStatusItem.pay_group = employment_status_form.pay_group;
					return employmentStatusItem;
				})
				.filter((item) => item);
			employee.employment_status = employmentStatus;

			const compensation: Compensation[] | undefined = employeeForm.compensation
				?.map((payment_form: ICompensation) => {
					const compensationItem: Compensation = new Compensation();
					compensationItem.effective_date = payment_form.effective_date;
					compensationItem.pay_rate = payment_form.pay_rate;
					compensationItem.change_reason = payment_form.change_reason;
					compensationItem.comment = payment_form.comment;
					compensationItem.transaction_currency = payment_form.transaction_currency;
					return compensationItem;
				})
				.filter((item) => item);

			employee.compensation = compensation;

			const emergencyContact: EmergencyContact[] | undefined = employeeForm.emergency_contact
				?.map((emergency_contact_form: IEmergencyContact) => {
					const emergencyContactItem: EmergencyContact = new EmergencyContact();
					emergencyContactItem.name = emergency_contact_form.name;
					emergencyContactItem.relationship = emergency_contact_form.relationship?.value;
					emergencyContactItem.primary_contact = emergency_contact_form.primary_contact;
					emergencyContactItem.work_phone = emergency_contact_form.work_phone;
					emergencyContactItem.work_phone_extension = emergency_contact_form.work_phone_extension;
					emergencyContactItem.mobile_phone = emergency_contact_form.mobile_phone;
					emergencyContactItem.home_phone = emergency_contact_form.home_phone;
					emergencyContactItem.email = emergency_contact_form.email;
					emergencyContactItem.address = emergency_contact_form.address;
					emergencyContactItem.city = emergency_contact_form.city;
					emergencyContactItem.province = emergency_contact_form.province;
					emergencyContactItem.zip_code = emergency_contact_form.zip_code;
					emergencyContactItem.country = emergency_contact_form.country;
					return emergencyContactItem;
				})
				.filter((item) => item);
			employee.emergency_contact = emergencyContact;

			const pto: Pto[] | undefined = employee.pto
				?.map((pto_form: IPto) => {
					const ptoItem: Pto = new Pto();
					ptoItem.start_date = pto_form.start_date;
					ptoItem.end_date = pto_form.end_date;
					ptoItem.pto_type = pto_form.pto_type;
					return ptoItem;
				})
				.filter((item) => item);
			employee.pto = pto;

			const reviews: Reviews[] | undefined = employeeForm.reviews
				?.map((review_form: IReviews) => {
					const reviewsItem: Reviews = new Reviews();
					reviewsItem.performed_date = review_form.performed_date;
					reviewsItem.status = review_form.status;
					reviewsItem.reviewer_name = review_form.reviewer_name;
					reviewsItem.reviewer_last_name = review_form.reviewer_last_name;
					return reviewsItem;
				})
				.filter((item) => item);
			employee.reviews = reviews;

			if (edition) {
				await employeeUseCases.editEmployeeUseCase(employee);
			} else {
				await employeeUseCases.createEmployeeUseCase(employee);
			}

			navigate("/");
		} catch (error) {
			if (error instanceof GeneralErrorResponse) {
				updateAlertState({
					show: true,
					data: {
						body: error.message,
						color: ALERT_STATE.error,
						title: "Employee form error",
					},
				});
			}
		} finally {
			setLoading(false);
		}
	};

	return {
		alertState,
		methods,
		handleSubmit,
		isDirty,
		dirtyFields,
		resetAllFields,
		register,
		onSubmit,
		navigate,
		defaultValues,
		reset,
	};
};
