import React, { useMemo } from "react";
import { ILoadingContextState } from "./IContexts";

const LoadingContext = React.createContext<ILoadingContextState | undefined>(undefined);

interface LoadingProviderProps {
	children: React.ReactNode;
}

export function LoadingContextProvider({ children }: LoadingProviderProps) {
	const [loading, setLoading] = React.useState(false);

	const contextValue = useMemo(() => ({ loading, setLoading }), [loading]);

	return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>;
}

export default LoadingContext;
