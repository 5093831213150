/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormContext } from "react-hook-form";
import React from "react";
import { EmergencyContact } from "../../../../domain/model/Employee";
import { DoubleInputContainer, FormInputsSectionContainer, SingleInputContainer } from "../../FormComponents";
import { GeneralCheckbox, GeneralInputInput } from "../../../Inputs/InputStyleComponents";
import { SelectInput } from "../../../Inputs/SelectInput/SelectInput";
import { RELATIONSHIP_OPTIONS, COUNTRY_OPTIONS } from "../../PersonalInfoForm/utils/InputOptions";

interface IEmergencyRegistryProps {
	emergencyContact: EmergencyContact;
	index: number;
	onFocusInput: () => void;
	key: string;
}

export function EmergencyRegistry({ emergencyContact, index, onFocusInput }: IEmergencyRegistryProps) {
	const { register } = useFormContext();
	return (
		<FormInputsSectionContainer>
			<div className='w-full lg:w-full'>
				<DoubleInputContainer>
					<div className='flex flex-col w-full'>
						<label htmlFor={`emergency-name-${index}`} className='text-gray-500'>
							Name
						</label>
						<GeneralInputInput
							{...register(`items.${index}.name`)}
							id={`emergency-name-${index}`}
							type='text'
							data-testid={`emergency-name-input-${index}`}
							onClick={onFocusInput}
						/>
					</div>

					<div className='flex flex-col w-full'>
						<label htmlFor='emergency-mobile-phone' className='text-gray-500'>
							Mobile Phone
						</label>
						<GeneralInputInput
							{...register(`items.${index}.mobile_phone`)}
							id={`emergency-mobile-phone-${index}`}
							type='text'
							data-testid={`emergency-mobile-phone-input-${index}`}
							onClick={onFocusInput}
						/>
					</div>
				</DoubleInputContainer>

				<DoubleInputContainer>
					<div className='flex flex-col w-full'>
						<label htmlFor='emergency-work-phone' className='text-gray-500'>
							Work Phone
						</label>
						<GeneralInputInput
							{...register(`items.${index}.work_phone`)}
							id={`emergency-work-phone-${index}`}
							type='text'
							data-testid={`emergency-work-phone-input-${index}`}
							onClick={onFocusInput}
						/>
					</div>

					<div className='flex flex-col w-full'>
						<label htmlFor='emergency-home-phone' className='text-gray-500'>
							Home Phone
						</label>
						<GeneralInputInput
							{...register(`items.${index}.home_phone`)}
							id={`emergency-home-phone-${index}`}
							type='text'
							data-testid={`emergency-home-phone-input-${index}`}
							onClick={onFocusInput}
						/>
					</div>
				</DoubleInputContainer>

				<SingleInputContainer>
					<div className='flex flex-col w-full'>
						<label htmlFor='emergency-address' className='text-gray-500'>
							Address
						</label>
						<GeneralInputInput
							{...register(`items.${index}.address`)}
							id={`emergency-address-${index}`}
							type='text'
							data-testid={`emergency-address-input-${index}`}
							onClick={onFocusInput}
						/>
					</div>
				</SingleInputContainer>

				<DoubleInputContainer>
					<div className='flex flex-col w-full'>
						<label htmlFor='emergency-city' className='text-gray-500'>
							City
						</label>
						<GeneralInputInput
							{...register(`items.${index}.city`)}
							id={`emergency-city-${index}`}
							type='text'
							data-testid={`emergency-city-input-${index}`}
							onClick={onFocusInput}
						/>
					</div>

					<div className='flex flex-col w-full'>
						<label htmlFor='emergency-country' className='text-gray-500'>
							Country
						</label>
						<SelectInput
							disabled={false}
							id={`items.${index}.country`}
							label="Working Country"
							options={COUNTRY_OPTIONS}
							isMulti={false}
							dataTestId={`emergency-country-select-${index}`}
							defaultValue={
								emergencyContact.country
									? {
											label: emergencyContact.country ?? "",
											value: emergencyContact.country ?? "",
									  }
									: undefined
							}
							onFocusSelect={onFocusInput}
						/>
					</div>
				</DoubleInputContainer>

				<DoubleInputContainer>
					<div className='flex flex-col w-full'>
						<label htmlFor='emergency-primary-contact' className='text-gray-500'>
							Primary Contact
						</label>
						<GeneralCheckbox
							{...register(`items.${index}.primary_contact`)}
							id={`emergency-primary-contact-${index}`}
							type='checkbox'
							data-testid={`emergency-primary-contact-checkbox-${index}`}
							onChange={onFocusInput}
						/>
					</div>
				</DoubleInputContainer>
			</div>

			<div className='w-full lg:w-1/2'>
				<SingleInputContainer>
					<label htmlFor='emergency-relashionship' className='text-gray-500'>
						Relationship
					</label>
					<SelectInput
						id={`items.${index}.relationship`}
						disabled={false}
						label="Relationship"
						options={RELATIONSHIP_OPTIONS}
						isMulti={false}
						dataTestId={`emergency-relashionship-input-${index}`}
						defaultValue={
							emergencyContact.relationship
								? {
										label: emergencyContact.relationship ?? "",
										value: emergencyContact.relationship ?? "",
								  }
								: undefined
						}
						onFocusSelect={onFocusInput}
					/>
				</SingleInputContainer>

				<SingleInputContainer>
					<label htmlFor='emergency-email' className='text-gray-500'>
						Email
					</label>
					<GeneralInputInput
						{...register(`items.${index}.email`)}
						id={`emergency-email-${index}`}
						type='text'
						data-testid={`emergency-email-input-${index}`}
						onClick={onFocusInput}
					/>
				</SingleInputContainer>

				<SingleInputContainer>
					<label htmlFor='zip_code' className='text-gray-500'>
						Zip Code
					</label>
					<GeneralInputInput
						{...register(`items.${index}.zip_code`)}
						id={`zip_code-${index}`}
						type='text'
						data-testid={`emergency-zip-code-input-${index}`}
						onClick={onFocusInput}
					/>
				</SingleInputContainer>

				<SingleInputContainer>
					<label htmlFor='province' className='text-gray-500'>
						Province
					</label>
					<GeneralInputInput
						{...register(`items.${index}.province`)}
						id={`province-${index}`}
						type='text'
						data-testid={`emergency-province-input-${index}`}
						onClick={onFocusInput}
					/>
				</SingleInputContainer>
			</div>
		</FormInputsSectionContainer>
	);
}
