import { useParams } from "react-router-dom";
import { useContext } from "react";
import { HeaderForms } from "../../components/HeaderForms/HeaderForms";
import { NavBar } from "../../components/NavBar/NavBar";
import { EmergencyForm } from "../../components/forms/EmergencyForm/EmergencyForm";
import { PersonalInfoForm } from "../../components/forms/PersonalInfoForm/PersonalInfoForm";
import { EducationForm } from "../../components/forms/EducationForm/EducationForm";
import { LateralBarForms } from "../../components/LateralBarForms/LateralBarForms";
import { useEmployee } from "../../hooks/employee/useEmployee";
import { useActivePage } from "../../hooks/employee/useActivePage";
import Loading from "../../components/Loading/Loading";
import { ILoadingContextState } from "../../contexts/IContexts";
import LoadingContext from "../../contexts/LoadingStateContext";
import { Alert } from "../../components/Alert/Alert";
import { getDateToString } from "../../utils/parsers";

export function EmployeeProfile() {
	const { employeeId } = useParams();

	if (!employeeId) {
		return <p>Employee Not Found</p>;
	}
	const { employee, alertState } = useEmployee({ employeeId });
	const { activePage, setActivePage } = useActivePage({
		pageName: "personalInfo",
	});
	const { loading } = useContext(LoadingContext) as ILoadingContextState;

	return (
		<>
			{loading && <Loading />}
			{alertState.show && <Alert {...alertState.data} />}
			<NavBar />
			<HeaderForms
				setActivePage={setActivePage}
				ioetId={employee?.ioet_id}
				job_title='Member of Technical Staff'
				userName={`${employee?.first_name} ${employee?.last_name}`}
			/>
			<section className='md:flex min-h-screen'>
				<LateralBarForms
					contractType={employee?.contract_type}
					departament='Departament'
					email={employee?.ioet_email}
					location='Location'
					phone={employee?.mobile_phone}
					startDate={getDateToString(employee?.start_date)}
					supervisorName='Jackie Perez'
					timeOnTheJob={employee?.time_on_the_job}
				/>
				{activePage === "personalInfo" && employee && <PersonalInfoForm employee={employee} />}
				{activePage === "emergency" && <EmergencyForm emergencyContacts={employee?.emergency_contact} />}
				{activePage === "education" && <EducationForm educationList={employee?.education} />}
			</section>
		</>
	);
}
