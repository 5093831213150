import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type BasicSelectProps = {
	values: string[] | undefined;
};
export default function BasicSelect({ values }: BasicSelectProps) {
	const initialValue = values?.[0] ?? ["no status"];

	const [value, setValue] = useState(initialValue);

	// eslint-disable-next-line eqeqeq
	const selectedValue = typeof value == "string" ? value : value[0];
	const handleChange = (event: SelectChangeEvent) => {
		setValue(event.target.value as string);
	};

	return (
		<FormControl fullWidth>
			<Select size='small' onChange={handleChange} label={value} value={selectedValue} fullWidth>
				{values?.map((item, index) => {
					return (
						<MenuItem key={index} value={item}>
							{item}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
