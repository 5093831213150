import { memo, useState } from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs, { Dayjs } from "dayjs";

interface CalendarProps {
	isDisabled?: boolean;
	dateValue?: string | null;
	children?: React.ReactNode;
}

function Calendar({ isDisabled = false, dateValue }: CalendarProps) {
	const getDateValues = dateValue ? dayjs(dateValue, "DD-MM-YYYY") : null;

	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(getDateValues || null);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				sx={{ "width": "auto", "& .MuiInputBase-root": { fontSize: "1em", alignContent: "center", height: "2.4rem" } }}
				disabled={isDisabled}
				slotProps={{ textField: { error: false } }}
				format='DD-MM-YYYY'
				value={selectedDate}
				onChange={(newValue) => setSelectedDate(newValue)}
			/>
		</LocalizationProvider>
	);
}

export default memo(Calendar);
