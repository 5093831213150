import { createContext, useMemo } from "react";
import axios from "axios";
import { IAuthContext } from "./IContexts";
import User from "../types/userType";
import { BACKEND_URL } from "../constants/authConstant";

const AuthContext = createContext<IAuthContext | undefined>({ getUserInformation: async () => undefined });

export function AuthProvider({ children }: { children: JSX.Element }) {
	async function getUserInformation(): Promise<User | undefined> {
		try {
			const res = await axios.get(`${BACKEND_URL}/user_information/`, {
				withCredentials: true,
			});
			if (res.status === 200) return res.data as User;
			return undefined;
		} catch (error) {
			return undefined;
		}
	}

	const contextValue = useMemo(() => ({ getUserInformation }), []);

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export default AuthContext;
