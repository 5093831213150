import { LoginPageContainer, LogoImage, LoginCard } from "./LoginStatusContainerStyleComponents";
import { LOGIN_URL } from "../../constants/urlConstants";
import { Button } from "../Button/Button";
import { StyleButton } from "../Button/StyleButton";
import people_app_logo_color from "../../assets/people_app_logo_color.png";
import "./LoginStatusContainer.css";

function LoginStatusContainer() {
	return (
		<LoginPageContainer id='animation'>
			<LoginCard>
				<LogoImage src={people_app_logo_color} alt='logo' />
				<StyleButton>
					<Button
						text='Login'
						callback={() => {
							window.location.href = LOGIN_URL;
						}}
					/>
				</StyleButton>
			</LoginCard>
		</LoginPageContainer>
	);
}

export default LoginStatusContainer;
