import { IButton } from "./IButton";
import { ButtonText } from "../ButtonText/ButtonText";

export function Button({ text, callback, type = "button" }: IButton) {
	return (
		// eslint-disable-next-line react/button-has-type
		<button type={type} onClick={callback} className='w-full'>
			<ButtonText text={text} />
		</button>
	);
}
