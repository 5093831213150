import tw from "twin.macro";

export const MainContainer = tw.div`
    pt-1
    xl:px-64

    `;

export const TitleContainer = tw.div`
    flex
    justify-between
    items-center
    bg-gradient-orange-to-red
    w-full
    text-white
    text-2xl
    font-bold
    md:px-32
    px-10
    `;
