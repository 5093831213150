import { PeopleRepositoryImpl } from "./peopleRepositoryImpl";
import fetcherDataFromBackend from "../utils/fetcher";
import { ListAllPeopleInputPort } from "../../domain/useCase/ListAllPeopleInputPort";
import { ListPeopleViewModel } from "../../pages/ListPeople/ListPeopleViewModel";
import { dataDefinitionSchema } from "../../constants/types";

const listAllPeopleUseCase: ListAllPeopleInputPort = new ListPeopleViewModel(new PeopleRepositoryImpl());

export const fetchPeople = async () => {
	const getPeople = await fetcherDataFromBackend(() => listAllPeopleUseCase.listAllPeopleUseCase(), dataDefinitionSchema);
	return getPeople;
};

export default fetchPeople;
