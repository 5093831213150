import tw from "twin.macro";

export const TabButtonContainer = tw.button`
    flex
    items-center
    text-white
    font-bold
    text-white
    hover:bg-white
    hover:text-ioet-orange
    border-ioet-orange
    border-b
    justify-center
    w-32
    pt-1
`;
